import React, { useState } from "react";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TimecardHours from "./TimecardHours";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TaxableExpenses from "./TaxableExpenses";
import TimecardFile from "./TimecardFile";
import FacilityReportFile from "../timecards/FacilityReportFile";
import NoteTypeResolver from "../notes/NoteTypeResolver";
import TimesheetApi from "../../api/TimesheetApi";
import { TimecardPanels, TimesheetFileTypes } from "../../commons/Enums";
import ExpensesPanel from "./ExpensesPanel";
import { useFeatureFlags } from "../../commons/Features";
import NonTaxableExpenses from "./NonTaxableExpenses";
import CanRenderChildren from "../shared/functions/CanRenderChildren";
import TimecardNotes from "components/notes/TimecardNotes";
import CallOffsSection from "./CallOffsSection";

const TimecardEntry = (props) => {
  const {
    jobAssignment,
    onTimecardEntriesChange,
    onTimecardAdjustmentEntriesChange,
    onAddAdjustment,
    onRemoveAdjustment,
    onTaxableExpensesChange,
    onBonusExpensesChange,
    onNonTaxableExpensesChange,
    onTimecardFileUploaded,
    onTimecardUploadDelete,
    onFacilityReportFileUploaded,
    onFacilityReportFilesAdded,
    onNotesRetrieved,
    onNoteChange,
    onNotifyTravelerChange,
    onAddNonTaxableAdjustment,
    onRemoveNonTaxableAdjustment,
    onNonTaxableAdjustmentsChange,
    onHandleOnExpenseChange,
    onRateChangesAcknowledged,
    onCallOffsChange,
  } = props;

  const [expanded, setExpanded] = useState(TimecardPanels.Timecard);
  const { missedShiftDeductVisible, isAdjustmentsOn } = useFeatureFlags();

  const handleChange = (panel) => {
    if (expanded === panel) {
      setExpanded("");
    } else {
      setExpanded(panel);
    }
  };

  const getNotes = () => {
    if (!jobAssignment.notes) {
      TimesheetApi.getNotesByAssigment(jobAssignment.id).then((data) => {
        onNotesRetrieved(data);
      });
    }
    return jobAssignment.notes;
  };

  return (
    <div className="timecard-timeentry">
      <Accordion
        expanded={expanded === TimecardPanels.Timecard}
        onChange={() => handleChange(TimecardPanels.Timecard)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>Timecard</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>
          <TimecardHours
            onAddAdjustment={(name) => onAddAdjustment(name)}
            onRemoveAdjustment={(name, order) => onRemoveAdjustment(name, order)}
            onTimecardEntriesChange={(entries, dtHoursChanged) =>
              onTimecardEntriesChange(entries, dtHoursChanged)
            }
            onTimecardAdjustmentEntriesChange={(entries) =>
              onTimecardAdjustmentEntriesChange(entries)
            }
            types={jobAssignment.timecardEntries}
            adjustments={isAdjustmentsOn ? jobAssignment.timecardAdjustmentTypes : []}
            key={jobAssignment.id}
            status={jobAssignment.status}
            invoiceDelivery={
              jobAssignment.billingEntries != null
                ? jobAssignment.billingEntries.filter((x) => x.tableOrder === 0)[0].textFieldValue
                : ""
            }
            missedLunchHours={jobAssignment.missedLunchHours}
            isDigitalTimecardEntry={jobAssignment.isDigitalTimecardEntry}
            clientState={jobAssignment.clientState}
            timesheetId={jobAssignment.id}
            hasToAcknowledgeRateChange={jobAssignment.hasToAcknowledgeRateChange}
            onRateChangesAcknowledged={onRateChangesAcknowledged}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === TimecardPanels.NonTaxable}
        onChange={() => handleChange(TimecardPanels.NonTaxable)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>Non Taxable</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {missedShiftDeductVisible ? (
            <NonTaxableExpenses
              isDigitalTimecardEntry={jobAssignment.isDigitalTimecardEntry}
              onChange={(entries) => onNonTaxableExpensesChange(entries)}
              expenses={jobAssignment.nonTaxableExpenses}
              isFirstWeek={jobAssignment.isFirstWeek}
              isLastWeek={jobAssignment.isLastWeek}
              nonTaxableAdjustments={jobAssignment.nonTaxableAdjustmentTypes}
              onAddAdjustment={(name) => onAddNonTaxableAdjustment(name)}
              onRemoveAdjustment={(name, order) => onRemoveNonTaxableAdjustment(name, order)}
              onNonTaxableAdjustmentEntriesChange={(entries) =>
                onNonTaxableAdjustmentsChange(entries)
              }
              handleOnExpenseChange={(name, newAmount) =>
                onHandleOnExpenseChange(name, newAmount)
              }></NonTaxableExpenses>
          ) : (
            <TaxableExpenses
              onChange={(entries) => onNonTaxableExpensesChange(entries)}
              expenses={jobAssignment.nonTaxableExpenses}
              key={jobAssignment.id}
              isFirstWeek={jobAssignment.isFirstWeek}
              isLastWeek={jobAssignment.isLastWeek}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === TimecardPanels.Taxable}
        onChange={() => handleChange(TimecardPanels.Taxable)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>Taxable</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TaxableExpenses
            onChange={(entries) => onTaxableExpensesChange(entries)}
            expenses={jobAssignment.taxableExpenses}
            key={jobAssignment.id}
            isFirstWeek={jobAssignment.isFirstWeek}
            isLastWeek={jobAssignment.isLastWeek}
          />
        </AccordionDetails>
      </Accordion>

      {jobAssignment.expensesInJobAssigment && jobAssignment.expensesInJobAssigment.length > 0 && (
        <Accordion
          expanded={expanded === TimecardPanels.Expenses}
          onChange={() => handleChange(TimecardPanels.Expenses)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>Expenses</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ExpensesPanel expenses={jobAssignment.expensesInJobAssigment} />
          </AccordionDetails>
        </Accordion>
      )}

      {jobAssignment.hasBonusSection && (
        <>
          <Accordion
            expanded={expanded === TimecardPanels.Bonus}
            onChange={() => handleChange(TimecardPanels.Bonus)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>Bonus</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TaxableExpenses
                onChange={(entries) => onBonusExpensesChange(entries)}
                expenses={jobAssignment.bonusExpenses}
                key={jobAssignment.id}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}

      {!jobAssignment.isExpenseOnly && !jobAssignment.isAdjustment && (
        <Accordion
          expanded={expanded === TimecardPanels.CallOffHours}
          onChange={() => handleChange(TimecardPanels.CallOffHours)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>Call Offs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CallOffsSection
              callOffs={jobAssignment.callOffEntries}
              onChange={(entries) => onCallOffsChange(entries)}
              isDigitalTimecardEntry={jobAssignment.isDigitalTimecardEntry}
            />
          </AccordionDetails>
        </Accordion>
      )}

      <TimecardFile
        timesheetId={jobAssignment.id}
        timecardUploadId={jobAssignment.timecardUploadId}
        onFileUploaded={onTimecardFileUploaded}
        uploading={jobAssignment.uploading & TimesheetFileTypes.TIMECARD}
        onDelete={onTimecardUploadDelete}
        isDigitalTimecardEntry={jobAssignment.isDigitalTimecardEntry}
      />
      <FacilityReportFile
        timesheetId={jobAssignment.id}
        facilityReportUploadId={jobAssignment.facilityReportUploadId}
        onFileUploaded={onFacilityReportFileUploaded}
        onFilesAdded={onFacilityReportFilesAdded}
        uploading={jobAssignment.uploading & TimesheetFileTypes.FACILITY_REPORT}
        isDigitalTimecardEntry={jobAssignment.isDigitalTimecardEntry}
      />
      <TimecardNotes
        notes={getNotes()}
        note={jobAssignment.note}
        noteNotifyTraveler={jobAssignment.notifyTraveler}
        onNoteChange={onNoteChange}
        onNotifyTravelerChange={onNotifyTravelerChange}
        noteTypeFilter={NoteTypeResolver.getNoteTypeFilterFromStatus(jobAssignment.status)}
      />
    </div>
  );
};

TimecardEntry.propTypes = {
  jobAssignment: PropTypes.object.isRequired,
  onTimecardEntriesChange: PropTypes.func.isRequired,
  onTimecardAdjustmentEntriesChange: PropTypes.func,
  onAddAdjustment: PropTypes.func,
  onRemoveAdjustment: PropTypes.func,
  onNonTaxableExpensesChange: PropTypes.func.isRequired,
  onBonusExpensesChange: PropTypes.func.isRequired,
  onTaxableExpensesChange: PropTypes.func.isRequired,
  onTimecardFileUploaded: PropTypes.func.isRequired,
  onTimecardUploadDelete: PropTypes.func.isRequired,
  onFacilityReportFileUploaded: PropTypes.func.isRequired,
  onFacilityReportFilesAdded: PropTypes.func.isRequired,
  onNotesRetrieved: PropTypes.func.isRequired,
  onNoteChange: PropTypes.func.isRequired,
  onNotifyTravelerChange: PropTypes.func.isRequired,
  featureFlags: PropTypes.array,
  onAddNonTaxableAdjustment: PropTypes.func,
  onRemoveNonTaxableAdjustment: PropTypes.func,
  onNonTaxableAdjustmentsChange: PropTypes.func,
  onHandleOnExpenseChange: PropTypes.func,
  onRateChangesAcknowledged: PropTypes.func,
  onCallOffsChange: PropTypes.func,
};

export default TimecardEntry;
